import React from "react"

import Alert from 'react-bootstrap/Alert'
import Spinner from 'react-bootstrap/Spinner'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import classes from './contactForm.module.css';

import { MdEmail } from 'react-icons/md'

class ContactForm extends React.Component {

  state = {
    validated: false,
    isSending: false,
    alert: {
      show: false,
      variant: null
    },
  };

  dismissAlert = () => {
    this.setState(prevState => ({
      alert: {
        ...prevState.alert,
        show: false
      }
    }))
  }

  handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    this.setState({ 
      validated: true,
      alert: {
        show: false,
        variant: null
      }
    });

    if (form.checkValidity() === true) {
      this.setState({ isSending: true });

      const data = {};
      const formElements = Array.from(form);
      formElements.map(input => (data[input.name] = input.value));
      delete data[""];
      
      // // post form to lambda
      let xhr = new XMLHttpRequest();
      xhr.open(form.method, form.action, true);
      xhr.setRequestHeader('Accept', 'application/json; charset=utf-8');
      xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');

      // Send the collected data as JSON
      xhr.send(JSON.stringify(data));

      // Callback function
      xhr.onloadend = response => {
        if (response.target.status === 200) {
          // The form submission was successful
          form.reset();
          this.setState({
            validated: false,
            isSending: false,
            alert: {
              show: true,
              variant: 'success'
            }
          })
        } else {
          // The form submission failed
          this.setState({
            isSending: false,
            alert: {
              show: true,
              variant: 'danger'
            }
          })
          console.error(response.target.response);
        }
      }
    }
  }

  alertMessage = () => {
    if (this.state.alert.variant === 'success') {
      return (<span>Thanks for reaching out! I'll get back to you shortly</span>)
    } else {
      return (
        <span>
          Ops, something went wrong.Kindly email me at {' '}
          <Alert.Link href="mailto:alvin.choong@alvinc.me">alvin.choong@alvinc.me</Alert.Link>
        </span>
      )
    }
  }

  render() {
    const formClass = this.props.formClass || classes.contactForm;
    return (
      <Form
        onSubmit={this.handleSubmit}
        noValidate
        validated={this.state.validated}
        id="contact-form"
        method="POST"
        action="https://api.alvinc.me/mail/static-site-mailer"
        className={formClass}
      >
        <Alert
          show={this.state.alert.show}
          variant={this.state.alert.variant}
          onClose={this.dismissAlert}
          dismissible>
          {this.alertMessage()}
        </Alert>
        <Form.Group controlId="formGroupName">
          <Form.Control type="text" name="name" aria-label="Name" placeholder="Name" required />
        </Form.Group>
        <Form.Group controlId="formGroupContactNo">
          <Form.Control type="tel" name="contactNo" aria-label="Contact No" placeholder="Contact No" />
        </Form.Group>
        <Form.Group controlId="formGroupEmail">
          <Form.Control type="text" name="email" aria-label="Email" placeholder="Enter email" required />
        </Form.Group>
        <Form.Group controlId="formGroupMessage">
          <Form.Control as="textarea" name="message" aria-label="Message" placeholder="How can i help you?" rows="5" required />
        </Form.Group>

        <Button id="submitBtn" type="submit" size="md" variant='secondary' disabled={this.state.isSending} block>
          {
            this.state.isSending ?
            <span className="m-1"><Spinner animation="grow" role="status" size="sm" className="mb-1 mr-1" />Sending...</span> :
            <span><MdEmail className="mb-1" size="1.5rem"/> Send Message</span>
          }
        </Button>
        
      </Form>
    )
  }
}

export default ContactForm